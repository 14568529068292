<template>
  <div class="text-center certificado-body">
    <v-dialog
      content-class="certification-dialog"
      v-model="dialog"
      transition="dialog-bottom-transition"
      v-if="certificadoLiberado"
      style="background: red"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="d-flex align-center gap-3">
          <v-btn
            @click="gerarpdf()"
            small
            color="#1f3566"
            class="gerarPdf text-white"
            v-if="evento.certificado"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon small class="mr-1">mdi-file-download-outline</v-icon>
            <span>baixar certificado</span>
          </v-btn>
          <v-btn
            icon
            color=""
            dark
            v-bind="attrs"
            v-on="on"
            @click="AddCertificado()"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  color="#1f3566"
                  class="gerarPdf text-white"
                  v-if="evento.certificado"
                >
                  <v-icon small v-bind="attrs" v-on="on">
                    mdi-eye
                  </v-icon>
                </v-btn>
              </template>
              <span>Visualizar certificado</span>
            </v-tooltip>
          </v-btn>
        </div>
      </template>

      <v-card>
        <v-toolbar dark>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Certificado</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="gerarpdf" id="ignorePDF"
            ><v-icon>mdi-file-download-outline</v-icon></v-btn
          >
        </v-toolbar>

        <div class="pdf-container" id="gerarpdf" v-if="loadedCertificate">
          <div class="content">
            <h1>Certificado</h1>

            <p>
              Certificamos que
              <strong v-text="usuario.nome"></strong>, portador do CPF <strong>{{usuario.cpf}}</strong> participou do evento
              <strong class="title-event" v-text="evento.titulo"></strong>, ministrado por  <strong v-text="palestrante"></strong>
            </p>
            

            <p>
              com carga horária de {{ evento.qtdHoras }} horas no dia
              {{ getDateTime(evento.dataInicio) }}.
            </p>

            <p>Certificado emitido em {{ getCertificateIssuance }}</p>

            <div class="check">
              <small
                >Código de verificação: <strong v-text="codigo"></strong
              ></small>
              <small
                >Para verificar a autenticidade deste documento, acesse:<br />
                <a href="https://eventos.pge.se.gov.br/verificarcertificado"
                  >eventos.pge.se.gov.br/verificarcertificado</a
                ></small
              >
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import bckgCertified from "@/assets/certificado.png";

import moment from "moment";

import dateMixins from "@/mixins/dateMixins.js";

import {
  GetStatusCertificado,
  criarCertificado,
  certificadoInfo,
  getEvento
} from "../services/api.js";

export default {
  name: "Certificado",

  mixins: [dateMixins],

  props: {
    evento: {
      type: Object,
      required: true,
    },
    usuario: {
      type: Object,
      required: true,
    },
    sub: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      dialog: false,
      certificadoLiberado: false,
      loadedCertificate: false,
      codigo: null,
      emitido: null,
      palestrante: '',
      images: {
        bckgCertified,
      },
    };
  },

  async mounted() {
    const req = await GetStatusCertificado(this.evento.id, this.usuario.id);
    if (req.status === 200) {
      this.certificadoLiberado = req.data;
    }
    //this.CertificadoInfo();
    moment.locale("pt-br");
    this.emitido = moment().format("LLL");
  },

  methods: {
    async AddCertificado() {
      await criarCertificado(this.sub);
      await this.CertificadoInfo();
      this.loadedCertificate = true;
    },

    async CertificadoInfo() {
      const req = await certificadoInfo(this.sub);
      if (req.status === 200) {
        this.codigo = req.data.codigo;
      }
      const reqEvento = await getEvento(this.evento.id);
      if(req.status === 200)
        this.palestrante = reqEvento.data.palestrante;
      
    },

    gerarpdf: async function() {
      this.loadedCertificate = false;
      await this.AddCertificado();

      // eslint-disable-next-line no-undef
      var doc = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: "a4",
      });

      doc.setFont("Helvetica");
      doc.setFontType("bold");
      doc.setFontSize(21);
      const maxWidth = 140;
      var heightOffset =
        (24 - doc.getTextDimensions(this.evento.titulo).h) / 2 + 71;

      // Background
      doc.addImage(this.images.bckgCertified, "PNG", 0, 0, 297, 210);

      // Certificado
      doc.setFontType("bold");
      doc.setFontSize(37);
      doc.setTextColor("0.007", "0.38", "0.678");
      doc.text("Certificado", 147, heightOffset);

      // Nome do usuário
      heightOffset += 10;
      doc.setFontType("normal");
      doc.setFontSize(12);
      doc.setTextColor("#AAAAAA");
      var splitUsername = doc.splitTextToSize(
        `Certificamos que ${this.usuario.nome} participou do evento`,
        maxWidth
      );
      doc.text(splitUsername, 147, heightOffset);
      heightOffset += splitUsername.length * 5 + 5;

      // Nome do evento
      heightOffset += 2;
      doc.setFontType("bold");
      doc.setFontSize(21);
      doc.setTextColor("#000000");
      var splitTitle = doc.splitTextToSize(this.evento.titulo, maxWidth);
      doc.text(splitTitle, 147, heightOffset);
      heightOffset += 10 + (splitTitle.length - 1) * 8.5;

      // Carga-horária
      heightOffset += 0;
      doc.setFontType("normal");
      doc.setFontSize(12);
      doc.setTextColor("#AAAAAA");
      doc.text(
        `com carga horária de ${
          this.evento.qtdHoras
        } horas no dia ${this.getDateTime(this.evento.dataInicio)}.`,
        147,
        heightOffset
      );
      heightOffset += 5;
      doc.text(
        `Certificado emitido em ${this.getCertificateIssuance}.`,
        147,
        heightOffset
      );

      // Verificacao
      heightOffset += 15;
      doc.setFontSize(10);
      doc.text("Código de verificação:", 147, heightOffset);

      heightOffset += 0;
      doc.setFontType("bold");
      doc.text(this.codigo, 184, heightOffset);

      heightOffset += 5;
      doc.setFontType("normal");
      doc.setFontSize(10);
      doc.text(
        "Para verificar a autenticidade deste documento, acesse:",
        147,
        heightOffset
      );
      heightOffset += 5;
      doc.textWithLink(
        "https://eventos.pge.se.gov.br/verificarcertificado",
        147,
        heightOffset,
        { url: "https://eventos.pge.se.gov.br/l/verificarcertificado" }
      );

      doc.save(`Certificado ${this.usuario.nome}.pdf`);
    },
  },

  computed: {
    getCertificateIssuance: function() {
      return this.emitido.replace(":", "h");
    },
  },
};
</script>

<style scoped lang="scss">
@import "Certificado.scss";
</style>

<style>
.certification-dialog {
  max-height: 100% !important;
  border-radius: 0.5rem;
  overflow: hidden;
  width: initial;
}
</style>
